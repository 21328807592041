import NumericInput from '../shared/numeric_input'
import ErrorMessage from '../shared/error_message'
import SimpleSelect from '../shared/simple_select'

export default {
  name: 'alarm-condition-form',
  template: '#alarm-condition-form-template',
  components: {ErrorMessage, NumericInput, SimpleSelect},
  props: {
    alarm: {
      type: Object,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      channelOptions: gon.channels,
    }
  },
  computed: {
    showPresets() {
      return this.valueOptions.length > 0
    },
    showValueAndUnit() {
      return !this.showPresets && this.alarm.requiresValueAndUnit()
    },
    valueOptions() {
      return this.alarm.valueOptions()
    },
    unitOptions() {
      return this.alarm.unitOptions()
    },
    conditionOptions() {
      return this.alarm.conditionOptions()
    },
    channelType() {
      if (!this.alarm.channel) { return null }

      const channelOption = this.channelOptions.find(
        channelOption => channelOption.channelNum == this.alarm.channel
      )
      if (!channelOption?.type) { return null }

      return channelOption.type
    }
  },
  methods: {
    onChannelChange() {
      this.alarm.condition = (this.conditionOptions[0] ? this.conditionOptions[0].value : '')
      this.alarm.unit = (this.unitOptions[0] ? this.unitOptions[0].value : '')

      this.onConditionChange()
    },
    onConditionChange() {
      this.alarm.unit = this.alarm.defaultUnit()
      this.alarm.value = null

      this.$emit('conditionChanged')
    },
    destroy() {
      this.$emit('destroy', this.alarm)
    },
  },
}
